import { Inject, Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { combineLatestWith, debounceTime, Observable, of, takeWhile, tap, filter, map } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { WINDOW } from '@ng-web-apis/common';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import {
  AccountMembershipView,
  CheckoutStep,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  QuickRenewSection,
  QuickRenewView,
  SessionDocResponse,
  SessionDocResponseObject,
  View,
} from '../hoosier.service';
import { FormGroup } from '@angular/forms';
import { FixMemberNamesService } from '../services/fix-member-names';
import { MemberInfoAttributes, Membership } from '../services/member-info';
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from '../../../../services/alert-message';
import { FormStatus } from '../../../../services/form';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import Timestamp = firebase.firestore.Timestamp;
import {
  connectSuiteMembershipCodes,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateRenewEventPayload,
  MembershipConnectSuiteRecostValidateRenewResponse,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { EventName } from '@aaa/interface-joinRenew-joinRenewLib';

export interface ValidateRenewResponseObject extends SessionDocResponseObject {
  error: ValidateRenewError;
  // meta: SessionDocMeta
  response: ValidateRenewResponse;
}

export interface ValidateRenewResponse extends MembershipConnectSuiteRecostValidateRenewResponse {
  executionData: string;
  validationData: ValidationData;
}

export interface ValidateRenewError extends MemberInfoAttributes {
  errorCode: number;
  error: string;
}

export interface ValidationData {
  attributes: {
    balance: string; // as of 12/15 use for everything other than join
    // useful within validateRenew,
    // does not exist within validateJoin
    // useful for addAssociate when pending (shows total amount)
    // changeLevel/upgrade and addAssociate when not pending, also want to consider attributes.totalCost and membership.costSummary.totalCost which both have same value as balance
    isAutoRenew: string;
    responseCode: string;
    responseText: string;
    totalCost: string; // as of 12/15 only use for join, or use membership.costSummary.totalCost
    // not useful with ValidateRenew, showing "0.0"
    // useful within validateJoin (balance key does not exist)
    // not useful within addAssociate when pending (showing just the new associate and it might be pro-rated, which is not useful when pending)
    version: string;
  };
  membership: ValidateMembership;
}

interface ValidateMembership extends Membership {
  costSummary?: {
    autoRenewDiscount: string;
    discountAmount: string;
    solicitationDiscount: string;
    solicitationRequiresAutoRenew: string;
    totalCost: string;
  };
}

/**
 * recostValidateRenew for:
 *   quickRenew (with memberNumber, lastName, and postalCode)
 *   myAccountRenew (with logged in member)
 */

@Injectable({
  providedIn: 'root',
})
export class ValidateRenewService {
  form: FormGroup;
  numberOfRetries = 0;

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
    private fixMemberNamesService: FixMemberNamesService,
    private notification: NzNotificationService,
  ) {
    this.form = hoosierService.form;
    rxEffects.register(this.recostValidateRenew$);
    rxEffects.register(this.VALIDATE$);
    this.form.get('renewPayload')?.enable(); // initialize all renewPayload formControl.valueChanges observables
    rxEffects.register(this.VALIDATE_KEY$);
    rxEffects.register(this.retryOnError$);
    rxEffects.register(this.activeChange$);
  }

  get recostValidateRenew$(): Observable<any> {
    const autoRenewForm = this.form.get(['renewPayload', 'autoRenew']);
    if (autoRenewForm) {
      return this.hoosierState.select('activeView').pipe(
        combineLatestWith(this.hoosierState.select('activeCheckoutStep'), autoRenewForm.valueChanges),
        debounceTime(100), // debounce is required, or else we get previous autoRenew value
        tap(
          ([activeView, activeCheckoutStep, autoRenew]: [
            View | null,
            CheckoutStep | null,
            MembershipConnectSuiteRecostValidateRenewEventPayload['autoRenew'],
          ]) => {
            const renewPayload: MembershipConnectSuiteRecostValidateRenewEventPayload =
              this.form.get('renewPayload')?.value;
            const VALIDATE_RENEW = this.hoosierState.get('VALIDATE_RENEW');
            switch (activeView) {
              case QuickRenewView.VALIDATE:
              case QuickRenewView.SUMMARY_AND_PAYMENT:
                // renewPayload.autoRenew = autoRenew
                this.recostValidateRenew(renewPayload);
                break;
              case AccountMembershipView.RENEW:
                if (activeCheckoutStep === CheckoutStep.PAYMENT) {
                  if (
                    !VALIDATE_RENEW ||
                    this.hoosierState.get('VALIDATE_RENEW_STATUS') === OpStatus.RUNNING ||
                    (VALIDATE_RENEW.response.validationData.attributes.isAutoRenew === 'true' &&
                      !renewPayload.autoRenew) ||
                    (VALIDATE_RENEW.response.validationData.attributes.isAutoRenew === 'false' &&
                      renewPayload.autoRenew)
                  ) {
                    // renewPayload.autoRenew = autoRenew
                    this.recostValidateRenew(renewPayload);
                  }
                }
                break;
            }
          },
        ),
      );
    }
    return of(null);
  }

  retryOnError$ = this.hoosierState.select('VALIDATE_RENEW_STATUS').pipe(
    takeWhile(() => this.numberOfRetries < 3),
    debounceTime(10000),
    tap((status) => {
      if (status === OpStatus.RETRY) {
        this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO);
        const renewPayload = this.form.get('renewPayload')?.value;
        if (renewPayload) {
          this.recostValidateRenew(renewPayload, true);
        }
      }
    }),
  );

  VALIDATE_KEY$ = this.hoosierState.select('VALIDATE_RENEW_KEY').pipe(
    combineLatestWith(
      this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite'),
      this.hoosierState.select('activeView'),
    ),
    tap(([VALIDATE_RENEW_KEY, connectsuite, activeView]: [string | null, SessionDocResponse, View | null]) => {
      if (VALIDATE_RENEW_KEY && connectsuite[VALIDATE_RENEW_KEY]) {
        // switch (activeView) {
        //   case QuickRenewView.VALIDATE:
        //   case AccountMembershipView.RENEW_PAYMENT:
        this.hoosierState.set('VALIDATE_RENEW_KEY', () => null);

        const responseTime = Timestamp.now().toMillis() - parseInt(VALIDATE_RENEW_KEY);
        if (this.globalState.get('adminUser')) {
          console.log(responseTime, 'milliseconds - VALIDATE_RENEW');
        }
        const validateResponseObject = connectsuite[VALIDATE_RENEW_KEY] as ValidateRenewResponseObject;
        const isError =
          validateResponseObject.meta.isError ||
          validateResponseObject.response.validationData?.membership?.attributes?.membershipStatus !== 'P';

        if (!isError) {
          this.alertMessageService.clearAll(AlertGroup.ERROR);

          validateResponseObject.response.validationData.membership = this.fixMemberNamesService.fixMemberNames(
            validateResponseObject.response.validationData.membership,
          );

          if (activeView === QuickRenewView.VALIDATE) {
            this.hoosierState.set('activeSection', () => QuickRenewSection.SUMMARY_AND_PAYMENT);
            this.hoosierState.set('activeView', () => QuickRenewView.SUMMARY_AND_PAYMENT);
          }

          this.hoosierState.set('VALIDATE_RENEW', () => validateResponseObject);
          this.hoosierState.set('VALIDATE_RENEW_ERROR', () => null);
          this.hoosierState.set('VALIDATE_RENEW_STATUS', () => OpStatus.SUCCESS);
        }

        if (isError) {
          this.hoosierState.set('VALIDATE_RENEW', () => null);
          this.hoosierState.set('VALIDATE_RENEW_ERROR', () => validateResponseObject);
          this.hoosierState.set('VALIDATE_RENEW_STATUS', () => OpStatus.IS_ERROR);
          // this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
        }
        // }
      }
    }),
  );

  VALIDATE$ = this.hoosierState.select('VALIDATE_RENEW').pipe(
    tap((VALIDATE_RENEW) => {
      if (VALIDATE_RENEW) {
        /**
         * assign balance from validateRenew to the template (via summary) and to the form (for credit card processing)
         */
        this.hoosierState.set('summary', (oldState) => {
          oldState.summary.balance = parseFloat(VALIDATE_RENEW.response.validationData.attributes.balance);
          return oldState.summary;
        });
        this.form
          .get(['paymentPayload', 'executionData', 'amountDetails', 'totalAmount'])
          ?.setValue(VALIDATE_RENEW.response.validationData.attributes.balance);

        const membership = VALIDATE_RENEW.response.validationData.membership;
        const membershipCode = connectSuiteMembershipCodes.find(
          (code) => code.duesComponentCode === membership.primaryMember.duesComponent.attributes.componentCode,
        );
        const summaryItems: {
          label: string;
          amount: number;
        }[] = [];
        summaryItems[0] = {
          amount: parseFloat(membership.primaryMember.duesComponent.attributes.componentAmount),
          label: 'AAA Membership',
        };
        if (membership.associateMember?.length) {
          for (const [index, associate] of membership.associateMember.entries()) {
            summaryItems[index + 1] = summaryItems[index + 1] || [];
            summaryItems[index + 1].label = '' + ' Associate Membership';
            summaryItems[index + 1].amount = parseFloat(associate.duesComponent.attributes.componentAmount);
          }
        }
        this.hoosierState.set('summary', (oldState) => {
          oldState.summary.items = summaryItems;
          return oldState.summary;
        });
        // this.summaryItems = summaryItems
        // this.totalBalance = parseFloat(VALIDATE.response.validationData.attributes.balance)

        /**
         * QuickRenew
         * unset useMemberInfoForBilling
         * prefill payment.billTo
         */
        if (this.hoosierState.get('activeView') === QuickRenewView.SUMMARY_AND_PAYMENT) {
          this.form.get('useMemberInfoForBilling')?.setValue(false);

          const billToForm = this.form.get(['paymentPayload', 'executionData', 'billTo']) as FormGroup;
          billToForm.get('firstName')?.setValue(membership.primaryMember.attributes.firstName);
          billToForm.get('lastName')?.setValue(membership.primaryMember.attributes.lastName);
          billToForm.get('email')?.setValue(membership.primaryMember.attributes.email);
          billToForm
            .get('phoneNumber')
            ?.setValue(
              membership.primaryMember.attributes.cellPhone || membership.primaryMember.attributes.homePhone || '',
            );
          billToForm.get('address1')?.setValue(membership.address.attributes.address1);
          billToForm.get('address2')?.setValue(membership.address.attributes.address2);
          billToForm.get('locality')?.setValue(membership.address.attributes.cityName);
          billToForm.get('administrativeArea')?.setValue(membership.address.attributes.StateProv);
          billToForm.get('district')?.setValue(membership.address.attributes.StateProv);
          billToForm.get('postalCode')?.setValue(membership.address.attributes.postalCode);
        }
      }
    }),
  );

  activeChange$ = this.hoosierState.select('activeChange').pipe(
    combineLatestWith(
      this.hoosierState.select('formStatus'),
      this.hoosierState.select('VALIDATE_RENEW_STATUS'),
      this.hoosierState.select('activeView'),
    ),
    filter(
      ([activeChange, formStatus, VALIDATE_RENEW_STATUS]) =>
        !!activeChange && formStatus !== FormStatus.PENDING && VALIDATE_RENEW_STATUS !== OpStatus.RUNNING,
    ),
    map(([, , , activeView]) => activeView),
    tap((activeView) => {
      const activeCheckoutStep = this.hoosierState.get('activeCheckoutStep');
      switch (activeView) {
        case QuickRenewView.LOGIN:
        case QuickRenewView.SUMMARY_AND_PAYMENT:
          this.hoosierState.set('activeChange', () => null);
          break;
        case AccountMembershipView.RENEW:
          if (activeCheckoutStep === CheckoutStep.PAYMENT) {
            this.hoosierState.set('activeChange', () => null);
          }
      }

      switch (activeView) {
        case QuickRenewView.LOGIN:
          if (!this.quickRenewLoginHasError()) {
            this.hoosierState.set(
              'activeSection',
              () => this.hoosierState.get('activeDestination') as QuickRenewSection,
            );
            this.hoosierState.set('activeView', () => this.hoosierState.get('activeDestination') as QuickRenewView);
          }
          break;
        case QuickRenewView.SUMMARY_AND_PAYMENT:
          if (!this.paymentSectionHasError()) {
            this.hoosierState.set(
              'activeSection',
              () => this.hoosierState.get('activeDestination') as QuickRenewSection,
            );
            this.hoosierState.set('activeView', () => this.hoosierState.get('activeDestination') as QuickRenewView);
          }
          break;
        case AccountMembershipView.RENEW:
          if (activeCheckoutStep === CheckoutStep.PAYMENT && !this.paymentSectionHasError()) {
            this.hoosierState.set('activeCheckoutStep', () => CheckoutStep.EXECUTE);
          }
          break;
      }
    }),
  );

  recostValidateRenew(renewPayload: MembershipConnectSuiteRecostValidateRenewEventPayload, retry?: boolean): void {
    if (retry) {
      this.numberOfRetries++;
    }
    if (!retry) {
      this.numberOfRetries = 0;
    }
    const responseKeyTimestampString = Timestamp.now().toMillis().toString();
    this.hoosierState.set('VALIDATE_RENEW_KEY', () => responseKeyTimestampString);
    this.hoosierState.set('VALIDATE_RENEW_STATUS', () => OpStatus.RUNNING);

    renewPayload.responseKey = responseKeyTimestampString;
    renewPayload.method = MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW;

    if (this.globalState.get('adminUser')) {
      // console.log(renewPayload)
    }

    this.joinRenewService
      .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, renewPayload)
      .then((response) => {
        if (this.globalState.get('adminUser')) {
          console.log(
            EventName.MEMBERSHIP_QUERY +
              '---' +
              MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW +
              '---' +
              responseKeyTimestampString +
              '---' +
              response,
          );
        }
      })
      .catch((error) => {
        this.hoosierState.set('VALIDATE_RENEW_STATUS', () => OpStatus.FAILED);
        // console.log(error)
      });
  }

  paymentSectionHasError() {
    let hasError = false;
    if (!this.form.get(['creditCard', 'expirationMonth'])?.value) {
      this.notification.blank('', 'Please choose your Credit Card Expiration Month');
      hasError = true;
    }
    if (!this.form.get(['creditCard', 'expirationYear'])?.value) {
      this.notification.blank('', 'Please choose your Credit Card Expiration Year');
      hasError = true;
    }
    if (!this.form.get(['creditCard', 'numberIsValid'])?.value) {
      this.notification.blank('', 'Please enter a valid credit card number');
      hasError = true;
    }
    if (!this.form.get(['creditCard', 'securityCodeIsValid'])?.value) {
      this.notification.blank('', 'Please enter 3-digit credit card verification code');
      hasError = true;
    }
    if (
      !this.form.get('useMemberInfoForBilling')?.value &&
      !this.form.get(['paymentPayload', 'executionData', 'billTo'])?.valid
    ) {
      hasError = true;
    }
    // if (this.hoosierState.get("PROMO_CODE_STATUS") === OpStatus.IS_ERROR) {
    //   this.notification.blank("", "Cannot proceed with an invalid Promo Code")
    //   hasError = true
    // }
    return hasError;
  }

  quickRenewLoginHasError() {
    let hasError = false;
    if (this.form.get(['renewPayload', 'memberNumber'])?.errors) {
      hasError = true;
    }
    if (this.form.get(['renewPayload', 'verificationData', 'lastName'])?.errors) {
      hasError = true;
    }
    if (this.form.get(['renewPayload', 'verificationData', 'postalCode'])?.errors) {
      hasError = true;
    }
    return hasError;
  }
}
