import { Inject, Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { combineLatest, combineLatestWith, debounceTime, filter, takeWhile, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { SESSION_STORAGE, WINDOW } from '@ng-web-apis/common';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { Flow, HOOSIER_RX_STATE, HoosierService, HoosierState, SessionDocResponseObject } from '../hoosier.service';
import { FixMemberNamesService } from '../services/fix-member-names';
import { MemberInfo } from '../services/member-info';
import { FormService } from '../../../../services/form';
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from '../../../../services/alert-message';
import { AnalyticsService } from '../../../../services/analytics';
import { AccountDetails, AccountStatus } from '../components/flows/account';
import { FormGroup } from '@angular/forms';
import {
  ConnectSuiteMembershipCode,
  connectSuiteMembershipCodes,
  LastNameSuffix,
  MembershipConnectSuiteMemberLookupEventPayload,
  MembershipConnectSuiteMemberLookupResponse,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteRecostValidateChangeAREventPayload,
  MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
  MembershipConnectSuiteRecostValidateJoinEventPayload,
  MembershipConnectSuiteRecostValidateRenewEventPayload,
  MembershipPayloadAssociate,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { EventName, SaveCacheSettings } from '@aaa/interface-joinRenew-joinRenewLib';
import Timestamp = firebase.firestore.Timestamp;

export interface MemberLookupResponseObject extends SessionDocResponseObject {
  error: MemberLookupErrorResponse;
  // meta: SessionDocMeta
  response: MemberLookupResponse;
}

export type MemberLookupErrorResponse = {
  balance: string; // "0.0",
  responseText: string; // "We were unable to locate your membership using the number provided.",
  version: string; // "1.3",
  responseCode: string; // "022"
};

export interface MemberLookupResponse extends MembershipConnectSuiteMemberLookupResponse {
  memberInfo: MemberInfo;
}

interface StoredLookup {
  memberNumber: string;
  afAuthIdToken: string;
  response: MemberLookupResponseObject;
  created: Timestamp;
}

@Injectable({
  providedIn: 'root',
})
export class MemberLookupService {
  form: FormGroup;
  numberOfRetries = 0;

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(SESSION_STORAGE)
    private sessionStorage: Storage,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private formService: FormService,
    private alertMessageService: AlertMessageService,
    private fixMemberNamesService: FixMemberNamesService,
    private analyticsService: AnalyticsService,
  ) {
    this.form = hoosierService.form;
    rxEffects.register(this.memberLookup$);
    rxEffects.register(this.MEMBER_LOOKUP_KEY$);
    rxEffects.register(this.retryOnError$);
    if (globalState.get('windowMetaData', 'user', 'memberNumber') === null) {
      this.resetAccountDetails();
    }

    rxEffects.register(this.MEMBER_LOOKUP_ERROR$);
    rxEffects.register(this.MEMBER_LOOKUP$);
  }

  memberLookup$ = this.globalState.select('windowMetaData', 'user', 'memberNumber').pipe(
    combineLatestWith(this.globalState.select('afAuthIdTokenResult')),
    tap(([memberNumber, afAuthIdTokenResult]: [string, firebase.auth.IdTokenResult | null]) => {
      const afAuthIdToken = afAuthIdTokenResult?.token;
      if (afAuthIdToken) {
        /**
         * may not need to retrieve this if the form is getting retrieved
         */
        const lookup = this.sessionStorage.getItem('lookup');
        if (lookup) {
          const storedLookup: StoredLookup = JSON.parse(lookup);
          if (
            storedLookup &&
            memberNumber === storedLookup.memberNumber &&
            afAuthIdToken === storedLookup.afAuthIdToken &&
            Timestamp.now().seconds > storedLookup.created.seconds + 15 * 60 // 15 minute cache
          ) {
            this.hoosierState.set('MEMBER_LOOKUP', () => storedLookup.response);
            this.sessionStorage.setItem('lookup', JSON.stringify(storedLookup));
            // this.hoosierState.set("MEMBER_LOOKUP_ERROR", () => null)
            return;
          }
        }
        /**
         * do member lookup
         */
        this.memberLookup(memberNumber, 'MEMBER_LOOKUP_KEY');
      }
    }),
  );

  retryOnError$ = this.hoosierState.select('MEMBER_LOOKUP_STATUS').pipe(
    takeWhile(() => this.numberOfRetries < 3),
    debounceTime(10000),
    filter((status) => status === OpStatus.IS_ERROR),
    tap(() => {
      this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO);
      const memberNumber = this.globalState.get('windowMetaData', 'user', 'memberNumber');
      this.memberLookup(memberNumber, 'MEMBER_LOOKUP_KEY', true);
    }),
  );

  MEMBER_LOOKUP_KEY$ = combineLatest([
    this.hoosierState.select('MEMBER_LOOKUP_KEY'),
    this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite'),
  ]).pipe(
    filter(([MEMBER_LOOKUP_KEY, connectsuite]) => {
      return !!MEMBER_LOOKUP_KEY && !!connectsuite[MEMBER_LOOKUP_KEY];
    }),
    tap(([MEMBER_LOOKUP_KEY, connectsuite]) => {
      this.alertMessageService.clear(AlertMessage.ERROR_CRITICAL);
      this.hoosierState.set('MEMBER_LOOKUP_KEY', () => null);

      const responseTime = Timestamp.now().toMillis() - parseInt(MEMBER_LOOKUP_KEY as string);
      if (this.globalState.get('adminUser')) {
        console.log(responseTime, 'milliseconds - MEMBER_LOOKUP');
      }

      const memberLookupResponseObject = connectsuite[MEMBER_LOOKUP_KEY as string] as MemberLookupResponseObject;
      const memberInfo = memberLookupResponseObject.response?.memberInfo;
      const memberLookupErrorObject = memberLookupResponseObject.error;
      const responseCode = memberInfo?.attributes?.responseCode || memberLookupErrorObject?.responseCode;

      switch (responseCode) {
        case '000': // success
        case '024': // cancelled
          this.alertMessageService.clearAll(AlertGroup.ERROR);
          // const memberInfo: MemberLookupResponse["memberInfo"] = memberLookupResponse?.memberInfo
          if (memberInfo?.membership) {
            memberInfo.membership = this.fixMemberNamesService.fixMemberNames(memberInfo.membership);
          }

          /**
           * don't need to store this if the form is getting stored
           */
          /*
              const storedLookup: StoredLookup = {
                memberNumber: this.globalState.get("windowMetaData", "user", "memberNumber"),
                afAuthIdToken: this.globalState.get("afAuthIdTokenResult", "token"),
                response: connectsuite[responseKey] as MemberLookupResponseObject,
                created: Timestamp.now()
              }
              this.sessionStorage.setItem("lookup", JSON.stringify(storedLookup))
          */

          /**
           * populate paymentPayload if needed
           */

          if (!memberInfo) {
            this.hoosierState.set('MEMBER_LOOKUP_ERROR', () => memberLookupResponseObject);
            this.hoosierState.set('MEMBER_LOOKUP', () => null);
            this.hoosierState.set('MEMBER_LOOKUP_STATUS', () => OpStatus.FAILED);
          } else {
            this.hoosierState.set('MEMBER_LOOKUP_ERROR', () => null);
            this.hoosierState.set('MEMBER_LOOKUP', () => memberLookupResponseObject);
            this.hoosierState.set('MEMBER_LOOKUP_STATUS', () => OpStatus.SUCCESS);
          }

          break;
        case '020': // invalid membership number
        case '022': // We were unable to locate your membership using the number provided.
          this.hoosierState.set('MEMBER_LOOKUP_ERROR', () => memberLookupResponseObject);
          this.hoosierState.set('MEMBER_LOOKUP', () => null);
          this.hoosierState.set('MEMBER_LOOKUP_STATUS', () => OpStatus.SUCCESS);
          break;
        default: // response code !== "000", not "success"
          this.hoosierState.set('MEMBER_LOOKUP_ERROR', () => memberLookupResponseObject);
          this.hoosierState.set('MEMBER_LOOKUP', () => null);
          this.hoosierState.set('MEMBER_LOOKUP_STATUS', () => OpStatus.IS_ERROR);
      }
    }),
  );

  MEMBER_LOOKUP_ERROR$ = this.hoosierState.select('MEMBER_LOOKUP_ERROR').pipe(
    filter((MEMBER_LOOKUP_ERROR) => !!MEMBER_LOOKUP_ERROR),
    tap(() => {
      this.resetAccountDetails(AccountStatus.GUEST);
      this.form.get('renewPayload')?.patchValue(this.hoosierService.newHoosierItem.renewPayload, { emitEvent: false });

      /**
       * error states can be when:
       *   responseCode === "020" invalid membership number
       *   responseCode === "024" user is cancelled
       *   responseCode !== "000"
       *
       * need to decide when to show an error message, normally this error will be ready within seconds of loading every page
       */
      // this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
    }),
  );

  MEMBER_LOOKUP$ = this.hoosierState.select('MEMBER_LOOKUP').pipe(
    combineLatestWith(this.hoosierState.select('activeFlow'), this.hoosierState.select('MEMBER_LOOKUP_STATUS')),
    tap(
      ([MEMBER_LOOKUP, activeFlow, MEMBER_LOOKUP_STATUS]: [
        MemberLookupResponseObject | null,
        Flow | null,
        OpStatus,
      ]) => {
        if (!MEMBER_LOOKUP && MEMBER_LOOKUP_STATUS !== OpStatus.RUNNING) {
          this.resetAccountDetails(AccountStatus.GUEST);
          this.form
            .get('renewPayload')
            ?.patchValue(this.hoosierService.newHoosierItem.renewPayload, { emitEvent: false });
        }
        if (MEMBER_LOOKUP) {
          this.resetAccountDetails();
          // this.hoosierState.set("MEMBERSHIP", () => null)
          // this.hoosierState.set("PAYMENT", () => null)
          switch (activeFlow) {
            case Flow.MOBILE_APP_RENEW:
            case Flow.ACCOUNT: {
              const memberInfo = MEMBER_LOOKUP.response.memberInfo;
              const expDate = memberInfo.membership.attributes.expDate;
              const expiresTimestamp = Date.parse(
                expDate.slice(0, 4) + '-' + expDate.slice(4, 6) + '-' + expDate.slice(6, 8),
              );

              let status: AccountDetails['status'];
              switch (memberInfo.membership.attributes.membershipStatus) {
                case 'P':
                  status = AccountStatus.PENDING;
                  break;
                case 'C':
                  status = AccountStatus.CANCELLED;
                  break;
                case 'A':
                  status = AccountStatus.ACTIVE;
                  break;
                default:
                  status = AccountStatus.UNKNOWN;
              }
              const membershipCode: ConnectSuiteMembershipCode | undefined = connectSuiteMembershipCodes.find(
                (code) => code.membershipType === memberInfo.membership.primaryMember.attributes.membershipType,
              );

              const membershipPayloadAssociates: MembershipPayloadAssociate[] = [];
              if (memberInfo.membership.associateMember) {
                for (const associate of memberInfo.membership.associateMember) {
                  membershipPayloadAssociates.push({
                    dob: associate.attributes.dob,
                    email: associate.attributes.email || '',
                    firstName: associate.attributes.firstName,
                    lastName: associate.attributes.lastName,
                    membershipNumber: associate.attributes.membershipNumber,
                    middleIntial: associate.attributes.middleIntial,
                    nameSuffix: associate.attributes.nameSuffix as LastNameSuffix,
                    removeAssociate: false,
                  });
                }
              }

              // this.statusText = this.accountDetails.expired ? "EXPIRED " : "EXPIRES "
              // this.statusText = this.statusText + this.accountDetails.expiresDateFormatted

              const isExpired = expiresTimestamp < Timestamp.now().toMillis();
              const expiresDateFormatted = this.hoosierService.expirationDateFormatted(expDate);
              const statusText = isExpired ? 'EXPIRED ' + expiresDateFormatted : 'EXPIRES ' + expiresDateFormatted;

              const accountDetails: AccountDetails = {
                // associatesCount: associatesDetails.length,
                autoRenew: 'Y' === memberInfo.membership.payment.attributes?.autoRenew,
                balance: parseFloat(memberInfo.attributes.balance),
                cardNumber: memberInfo.membership.payment?.account?.attributes?.cardNumber || '',
                code: membershipCode,
                expired: isExpired,
                expiresDateFormatted: expiresDateFormatted,
                expiresDateString: expDate,
                expiresTimestamp: expiresTimestamp,
                status: status,
                statusText: statusText,

                /**
                 * the following are available from the membership payload object
                 *   - MembershipLookupService.copyMemberLookupToMembershipPayload()
                 * and don't need to be defined here TODO: remove them when costs component is using membershipPayload
                 */
                address: {
                  street1: memberInfo.membership.address.attributes.address1,
                  street2: memberInfo.membership.address.attributes.address2,
                  city: memberInfo.membership.address.attributes.cityName,
                  state: memberInfo.membership.address.attributes.StateProv,
                  zip: memberInfo.membership.address.attributes.postalCode,
                },
                associateCount: membershipPayloadAssociates.length,
                associates: membershipPayloadAssociates,
                email: memberInfo.membership.primaryMember.attributes.email || '',
                firstName: memberInfo.membership.primaryMember.attributes.firstName,
                lastName: memberInfo.membership.primaryMember.attributes.lastName,
                memberNumber: memberInfo.membership.primaryMember.attributes.membershipNumber,
                middleInitial: memberInfo.membership.primaryMember.attributes.middleIntial,
                nameSuffix: memberInfo.membership.primaryMember.attributes.nameSuffix,
                phone: {
                  cell: memberInfo.membership.primaryMember.attributes.cellPhone || '',
                  home: memberInfo.membership.primaryMember.attributes.homePhone || '',
                  business: memberInfo.membership.primaryMember.attributes.businessPhone || '',
                },
              };

              const renewPayload: MembershipConnectSuiteRecostValidateRenewEventPayload = {
                autoRenew: accountDetails.autoRenew,
                memberNumber: accountDetails.memberNumber,
                method: MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW,
                verificationData: {
                  lastName: accountDetails.lastName,
                  postalCode: accountDetails.address.zip,
                },
              };

              if (membershipCode) {
                const membershipPayload: MembershipConnectSuiteRecostValidateJoinEventPayload = {
                  associates: membershipPayloadAssociates,
                  donorMembership: null,
                  membership: {
                    address: memberInfo.membership.address.attributes,
                    associateCount: memberInfo.membership.associateMember?.length || 0,
                    autoRenew: accountDetails.autoRenew,
                    cardFormat: null,
                    couponCode: '',
                    membershipType: 'B',
                    promoCode: '',
                    rv: true,
                    programCode: '',
                  },
                  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_JOIN,
                  primary: {
                    cellPhone: memberInfo.membership.primaryMember.attributes.cellPhone || '',
                    dob: memberInfo.membership.primaryMember.attributes.dob || '',
                    email: memberInfo.membership.primaryMember.attributes.email || '',
                    firstName: memberInfo.membership.primaryMember.attributes.firstName || '',
                    homePhone: memberInfo.membership.primaryMember.attributes.homePhone || '',
                    businessPhone: memberInfo.membership.primaryMember.attributes.businessPhone || '',
                    lastName: memberInfo.membership.primaryMember.attributes.lastName || '',
                    middleIntial: memberInfo.membership.primaryMember.attributes.middleIntial || '',
                    nameSuffix: (memberInfo.membership.primaryMember.attributes.nameSuffix as LastNameSuffix) || '',
                    sex: memberInfo.membership.primaryMember.attributes.sex || '',
                    title: memberInfo.membership.primaryMember.attributes.title || '',
                  },
                  responseKey: '',
                };
                this.form.get('membershipPayload')?.patchValue(membershipPayload, { emitEvent: false });
              }

              const changeAssociatesPayload: MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload = {
                associateCount: 0,
                associates: [],
                autoRenew: accountDetails.autoRenew,
                memberNumber: accountDetails.memberNumber,
                method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ASSOCIATES,
              };

              const changeAutoRenewPayload: MembershipConnectSuiteRecostValidateChangeAREventPayload = {
                autoRenew: true, // covers adding AR and change card, component sets to false for AR removal
                memberNumber: accountDetails.memberNumber,
                method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR,
              };

              const changeLevelPayload: MembershipConnectSuiteRecostValidateChangeLevelEventPayload = {
                autoRenew: accountDetails.autoRenew,
                memberNumber: accountDetails.memberNumber,
                method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL,
                newMembershipType: 'B',
              };

              this.hoosierState.set('accountDetails', () => accountDetails);
              this.form.get('changeAssociatesPayload')?.patchValue(changeAssociatesPayload, { emitEvent: false });
              this.form.get('changeAutoRenewPayload')?.patchValue(changeAutoRenewPayload, { emitEvent: false });
              this.form.get('changeLevelPayload')?.patchValue(changeLevelPayload, { emitEvent: false });
              this.form.get('renewPayload')?.patchValue(renewPayload, { emitEvent: false });
            }
          }
        }
      },
    ),
  );

  memberLookup(memberNumber: string, responseKeyName: keyof HoosierState, retry?: boolean): void {
    if (memberNumber) {
      if (retry) {
        this.numberOfRetries++;
      }
      if (!retry) {
        this.numberOfRetries = 0;
      }
      const responseKey: string = Timestamp.now().toMillis().toString();
      this.hoosierState.set(responseKeyName, () => responseKey);
      this.hoosierState.set('MEMBER_LOOKUP_STATUS', () => OpStatus.RUNNING);

      const cacheSettings: SaveCacheSettings = {
        cacheType: 'save',
        context: memberNumber,
        maxAge: Timestamp.now().toMillis() - 24 * 3600 * 1000, // get latest cache up to 1 days ago
      };

      const payload: MembershipConnectSuiteMemberLookupEventPayload = {
        cacheSettings: cacheSettings,
        memberNumber: memberNumber,
        method: MembershipConnectSuiteMethod.MEMBER_LOOKUP,
        responseKey: responseKey,
      };

      if (this.globalState.get('adminUser')) {
        // console.log(payload)
      }
      this.joinRenewService
        .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, payload)
        .then((response) => {
          if (this.globalState.get('adminUser')) {
            console.log(
              EventName.MEMBERSHIP_QUERY +
                '---' +
                MembershipConnectSuiteMethod.MEMBER_LOOKUP +
                '---' +
                responseKey +
                '---' +
                response,
            );
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }

  resetAccountDetails(accountStatus?: AccountStatus): void {
    this.hoosierState.set('accountDetails', () => {
      const user = this.globalState.get('windowMetaData', 'user');
      const accountDetails: AccountDetails = {
        address: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
        },
        associateCount: null,
        associates: [],
        // associatesCount: undefined,
        autoRenew: null,
        balance: null,
        cardNumber: '',
        code: null,
        email: user.email,
        expired: null,
        expiresDateFormatted: '',
        expiresDateString: '',
        expiresTimestamp: null,
        firstName: user.firstName,
        lastName: user.lastName,
        memberNumber: '',
        middleInitial: '',
        nameSuffix: '',
        phone: {
          cell: '',
          home: '',
          business: '',
        },
        status: accountStatus || AccountStatus.UNKNOWN,
        statusText: '',
      };
      return accountDetails;
    });
  }
}
